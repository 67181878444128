export default {
  // 上传图片业务名称
  UploadType: {
    Avatar: "avatar",
    Banner: "banner",
    MemberLevel: "memberlevel",
    Product: "product",
    ProductVideo: "productvideo",
    ExpressLogo: "expresslogo",
    Bank: "bankicon",
    BankSlip: "bankslip",
    PayLogPic: "paylogpic",
    ProductBrand: "productbrand",
    ProductClass: "productclass",
  },
  // 会员状态枚举  10:待审核 20:正常 50.禁用  60.注销 -99删除
  MemberStatusEnum: [
    { ID: 10, CN: "待审核" },
    { ID: 20, CN: "正常" },
    { ID: 50, CN: "禁用" },
    { ID: 60, CN: "注销" },
    { ID: -99, CN: "已删除" },
  ],
  MemberIdentityEnum: [
    { ID: 10, CN: "普通用户" },
    { ID: 15, CN: "主播" },
    { ID: 20, CN: "分销商" },
    { ID: 30, CN: "管理员" },
    { ID: 99, CN: "其他" },
  ],
  // 运费类型枚举
  FreightTypeEnum: [
    { ID: 3, CN: "现付" },
    { ID: 2, CN: "到付" },
  ],
  // 订单状态枚举
  OrderStatusEnum: [
    { ID: 10, CN: "预定" },
    { ID: 20, CN: "已取消" },
    { ID: 30, CN: "待发货" },
    { ID: 40, CN: "待收货" },
    { ID: 50, CN: "已完成" },
    { ID: 70, CN: "已退款" },
    { ID: -99, CN: "删除" },
  ],
  // 支付类型枚举
  PayTypeEnum: [
    { ID: 10, CN: "微信JSAPI" },
    { ID: 20, CN: "微信H5" },
    { ID: 30, CN: "支付宝" },
    { ID: 40, CN: "奖金支付" },
    { ID: 99, CN: "其他" },
  ],
  // 订单类型枚举
  OrderTypeEnum: [{ ID: 10, CN: "普通订单" }],
  // 支付状态枚举
  PayStatusEnum: [
    { ID: 0, CN: "未支付" },
    { ID: 1, CN: "支付失败" },
    { ID: 2, CN: "支付成功" },
    { ID: 3, CN: "已退款" },
    { ID: 4, CN: "部分退款" },
  ],
  // 支付记录类型枚举
  PayLogTypeEnum: [{ ID: 0, CN: "订单支付" }],
  // 商品状态枚举
  ProductStatusEnum: [
    { ID: 10, CN: "待上架" },
    { ID: 20, CN: "上架" },
    { ID: 30, CN: "下架" },
    { ID: -99, CN: "删除" },
    // { ID: 4, CN: '上架并推荐' }
  ],
  // 订单渠道/来源枚举
  OrderChannelEnum: [
    { ID: 10, CN: "H5" },
    { ID: 20, CN: "Android" },
    { ID: 30, CN: "IOS" },
    { ID: 40, CN: "小程序" },
  ],
  // 物流状态枚举
  FreightStatusEnum: [
    { ID: 10, CN: "启用" },
    { ID: 20, CN: "禁用" },
    { ID: -99, CN: "删除" },
  ],
  // 性别
  SexEnum: [
    { ID: 1, CN: "男" },
    { ID: 0, CN: "女" },
  ],
  // 支付配置类型枚举
  PayConfigTypeEnum: [
    { ID: 1, CN: "支付宝" },
    { ID: 2, CN: "微信公众号" },
    { ID: 3, CN: "微信APP" },
    { ID: 4, CN: "微信小程序" },
    { ID: 5, CN: "微信H5" },
  ],
  // 商品服务
  ProductServiceEnum: [
    { ID: 15, CN: "七天无理由退货" },
    { ID: 16, CN: "全国包邮" },
  ],
  // 商品限制
  ProductLimitEnum: [
    { ID: 1, CN: "不支持单拍" },
    { ID: 2, CN: "不支持加购" },
    { ID: 3, CN: "测试商品" },
  ],
  // 产品售罄状态枚举
  ProductIsSellOutEnum: [
    { ID: 1, CN: "是" },
    { ID: 0, CN: "否" },
  ],
  // 商品栏目枚举
  ProductTopicEnum: [
    { ID: "10", CN: "普通商品" },
    { ID: "20", CN: "热门推荐" },
  ],
  // 系统配置类型枚举
  SysConfigTypeEnum: [
    { ID: 10, CN: "系统配置" },
    { ID: 20, CN: "前端返回" },
    { ID: 30, CN: "其他" },
  ],
  // 支付配置状态枚举
  PayConfigStatusEnum: [
    { ID: 1, CN: "启用" },
    { ID: 0, CN: "禁用" },
  ],
  // 支付配置类型枚举
  BonusAddTypeEnum: [
    { ID: 10, CN: "收入" },
    { ID: 20, CN: "支出" },
  ],
  // 奖金类型枚举
  BonusTypeEnum: [
    { ID: 100, CN: "推荐奖金" },
    { ID: 105, CN: "订单取消退还支付奖金" },
    { ID: 200, CN: "奖金提现" },
    { ID: 205, CN: "订单退款，扣除推荐奖金" },
    { ID: 300, CN: "本人订单退款" },
    { ID: 400, CN: "订单支付" },
  ],
  // 奖金提现状态枚举
  BonusDrawStatusEnum: [
    { ID: 10, CN: "待处理" },
    { ID: 20, CN: "提现完成" },
    { ID: 30, CN: "申请被驳回" },
  ],
  // 广告打开方式枚举
  AdvertTargetEnum: [
    { ID: "_blank", CN: "_blank" },
    { ID: "_self", CN: "_self" },
    { ID: "_parent", CN: "_parent" },
    { ID: "_top", CN: "_top" },
  ],
  // 广告状态枚举
  AdvertStatusEnum: [
    { ID: 10, CN: "草稿" },
    { ID: 30, CN: "已发布" },
    { ID: 20, CN: "未发布" },
  ],
  // 广告位版本
  PositionVersionEnum: [{ ID: 1, CN: "V1.0" }],
  // 广告位置枚举
  AdvertPositionEnum: [{ ID: 1, CN: "首页顶部" }],
};
