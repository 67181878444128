<template>
  <Modal v-model="isShow" title="银行配置" class-name="i-modal" width="700px">
    <Form
      :model="obj"
      class="modelViewFrom"
      ref="form"
      label-position="right"
      :rules="validateRules"
      :label-width="80"
    >
      <Row :gutter="16">
        <Col span="12">
          <FormItem prop="BankIcon" label="银行图标:">
            <i-upload
              :fileKey="UploadType.Bank"
              @onChange="onChange"
              ref="UploadBank"
            ></i-upload>
          </FormItem>
        </Col>
        <Col span="12">
          <FormItem prop="BgImage" label="背景图片:">
            <i-upload
              :fileKey="UploadType.Bank"
              @onChange="onChange2"
              ref="UploadBank2"
            ></i-upload>
          </FormItem>
        </Col>
      </Row>
      <Row :gutter="16">
        <Col span="12">
          <FormItem prop="BankCode" label="银行编号:">
            <Input type="text" v-model="obj.BankCode"></Input>
          </FormItem>
        </Col>
        <Col span="12">
          <FormItem prop="Status" label="状态:">
            <Select clearable v-model="obj.Status" placeholder="状态">
              <Option key="0" :value="10">启用</Option>
              <Option key="1" :value="20">禁用</Option>
            </Select>
          </FormItem>
        </Col>
      </Row>
      <Row :gutter="16">
        <Col span="12">
          <FormItem prop="BankName" label="银行名称:">
            <Input type="text" v-model="obj.BankName"></Input>
          </FormItem>
        </Col>
        <Col span="12">
          <FormItem prop="Index" label="排序:">
            <Input type="text" v-model="obj.Index"></Input>
          </FormItem>
        </Col>
      </Row>
    </Form>
    <div slot="footer">
      <Button @click="isShow = false">取消</Button>
      <Button
        v-if="isEdit || isAdd"
        type="primary"
        @click="handleSave"
        :loading="SaveLoading"
      >
        {{ !SaveLoading ? "保存" : "保存中..." }}
      </Button>
    </div>
  </Modal>
</template>
<script>
import api from "@/api";
import bizEnum from "@/libs/bizEnum";
import iUpload from "@/components/upload/index.vue";
export default {
  name: "bank-edit-modal",
  data() {
    return {
      isShow: false,
      isEdit: false,
      isAdd: true,
      SaveLoading: false,
      loading: false,
      files: [],
      files2: [],
      obj: {},
      validateRules: {
        BankCode: [{ required: true, message: "银行编码不能为空" }],
        Status: [{ required: true, message: "状态不能为空" }],
        BankName: [{ required: true, message: "银行名称不能为空" }],
        Index: [{ required: true, message: "排序不能为空" }],
      },
    };
  },
  methods: {
    onChange(opt) {
      this.files = opt.files;
    },
    onChange2(opt) {
      this.files2 = opt.files;
    },
    async handleSave() {
      const _this = this;
      try {
        if (_this.files.length <= 0) {
          _this.$Message.warning("请上传银行图标");
          return;
        }
        if (_this.files2.length <= 0) {
          _this.$Message.warning("请上传银行背景");
          return;
        }
        _this.$refs.form.validate(async (valid) => {
          if (valid) {
            _this.SaveLoading = true;
            const res = await _this.$refs.UploadBank.Upload();
            const res2 = await _this.$refs.UploadBank2.Upload();
            if (res.success.length !== 0 && res2.success.length) {
              _this.obj.BankIcon = res.success[0];
              _this.obj.BgImage = res2.success[0];
              const response = await api.SaveBank({
                entity: _this.obj,
              });
              if (response.Status === 100) {
                _this.$Message.success("保存成功！");
                this.$emit("on-save", response.Data);
                _this.SaveLoading = false;
                _this.isShow = false;
                _this.$emit("on-save", this.obj);
              } else {
                _this.SaveLoading = false;
                _this.isShow = false;
                _this.$Message.error(response.Msg);
              }
            } else {
              _this.$Message.error("上传失败");
              this.SaveLoading = false;
            }
          } else {
            _this.SaveLoading = false;
            _this.$Message.error("填写信息有误！");
          }
        });
      } catch (error) {
        _this.$Notice.error({
          title: "错误",
          desc: error,
        });
        this.SaveLoading = false;
      }
    },
  },
  watch: {
    isShow: function (val) {
      if (val && this.obj.BankIcon) {
        this.files = this.$refs.UploadBank.uploadFiles = [
          {
            _key: this.obj.BankIcon,
            _file: this.$setImagePrefix(this.obj.BankIcon),
            success: true,
          },
        ];
        this.files2 = this.$refs.UploadBank2.uploadFiles = [
          {
            _key: this.obj.BgImage,
            _file: this.$setImagePrefix(this.obj.BgImage),
            success: true,
          },
        ];
      }
      if (val && !this.obj.BankIcon) {
        this.$refs.UploadBank.uploadFiles = [];
        this.$refs.UploadBank2.uploadFiles = [];
      }
    },
  },
  computed: {
    PayTypeEnum() {
      return bizEnum.PayTypeEnum;
    },
    UploadType() {
      return bizEnum.UploadType;
    },
  },
  components: { iUpload },
};
</script>
